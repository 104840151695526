<!--  -->
<template>
  <div class="line">
    <div id="line"></div>
  </div>
</template>
<script>
export default {
  props: {
    lineList: {
      type: Array,
      required: true,
    },
  },
  components: {},
  data() {
    //这里存放数据
    return {
      show: false,
      line: null,
      listX: [],
      listY: [],
    };
  },

  //监控data中的数据变化
  watch: {
    lineList: {
      handler: function (val) {
        if (val.length == 0) {
          this.show = true;
        } else {
          this.listX = [];
          this.listY = [];
          val.forEach((v) => {
            this.listX.push(v.userName);
            this.listY.push(v.counts);
          });
          this.setEcharts();
        }
      },
    },
  },
  //方法集合
  methods: {
    setEcharts() {
      let _that = this;
      if (_that.line != null && _that.line != "" && _that.line != undefined) {
        _that.line.dispose(); //销毁
      }
      var option = {
        tooltip: {
          show: true,
          trigger: "item",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          top: "40px",
          left: "10px",
          right: "20px",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: _that.listX,
            axisTick: {
              show: false,
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: true,
            },
            splitLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "60%",
            data: _that.listY,
            itemStyle: {
              
              borderRadius: 5,
            },
            barWidth: 10,
          },
        ],
      };
      _that.line = _that.$echarts.init(document.getElementById("line"));
      _that.line.setOption(option);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='less' scoped>
.line {
  width: 100%;
  #line {
    width: 95%;
    margin: auto;
    height: 33vh;
    background-color: #fff;
    border-radius: 5%;
  }
}
</style>