<!--  -->
<template>
  <div class="bar">
    <div id="bar"></div>
  </div>
</template>

<script>
export default {
  props: {
    barList: {
      type: Array,
      required: true,
    },
  },
  watch: {
    barList: {
      handler: function (val) {
        if (val.length == 0) {
          this.show = true;
        } else {
          this.bar_List = [];
          val.forEach((v) => {
            this.bar_List.push({
              value: v.counts,
              name: v.errName,
            });
          });
          this.setEcharts();
        }
      },
    },
  },
  components: {},
  data() {
    //这里存放数据
    return {
      bar_List: [],
      bar: null,
      show: false,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //方法集合
  methods: {
    setEcharts() {
      var _that = this;
      if (_that.bar != null && _that.bar != "" && _that.bar != undefined) {
        _that.bar.dispose(); //销毁
      }
      var option = {
        color: [
          "#006cff",
          "#60cda0",
          "#ed8884",
          "#ff9f7f",
          "#0096ff",
          "#9fe6b8",
          "#32c5e9",
          "#1d9dff",
        ],
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            top: "10%",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: _that.bar_List,
          },
        ],
      };
      _that.bar = _that.$echarts.init(document.getElementById("bar"));
      _that.bar.setOption(option);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='less' scoped>
.bar {
  width: 100%;
  #bar {
    width: 95%;
    margin: auto;
    height: 33vh;
    background-color: #fff;
    border-radius: 5%;
  }
}
</style>