<template>
  <div class="borad">
    <scroll class="borad_scroll" :onLoadMore="onLoadMore">
      <div class="information">
        <div class="information_l">
          <div>生产任务</div>
          <span class="icon icon-iconfontxiaogantanhao"></span>
        </div>
        <div class="information_r">
          <div
            v-for="item in dateList"
            :class="item.isActive == true ? 'active' : ''"
            :key="item.id"
            @click="headleDatChange(item.id)"
          >
            {{ item.dateItem }}
          </div>
        </div>
      </div>
      <div class="borad_task">
        <div class="borad_task_item">
          <div class="item">{{ planCounts }}</div>
          <div>计划数量</div>
        </div>
        <div class="borad_task_item">
          <div class="item">{{ fulfillCounts }}</div>
          <div>完成数量</div>
        </div>
        <div class="borad_task_item">
          <div class="item">{{ workOrderCounts }}</div>
          <div>工单数量</div>
        </div>
        <div class="borad_task_item">
          <div class="rate">{{ bad }}</div>
          <div>不良品率</div>
        </div>
      </div>
      <div class="ranking">员工绩效排名</div>
      <div>
        <div v-show="lineList.length == 0" class="conten_empty">
          <div class="conten_empty_img">
            <img src="../../common/img/kong.png" />
          </div>
          <div class="establish">
            今天还没有报工任务哦，快去
            <a @click="toWorkOrder()">
              <router-link to="">新建报工 </router-link>
              吧!
            </a>
          </div>
        </div>
        <lineEcharts
          v-show="lineList.length > 0"
          :lineList="lineList"
        ></lineEcharts>
      </div>
      <div class="ranking">不良品分布</div>
      <div>
        <div v-show="barList.length == 0" class="conten_empty">
          <div class="conten_empty_img">
            <img src="../../common/img/kong.png" />
          </div>
          <div class="establish">
            今天还没有报工任务哦，快去
            <a @click="toWorkOrder()">
              <router-link to="">新建报工 </router-link>
            </a>
            吧!
          </div>
        </div>
        <barEcharts v-show="barList.length > 0" :barList="barList"></barEcharts>
      </div>
    </scroll>
  </div>
</template>
<script>
import scroll from "../../components/scroll/index";
import { ChangePageTitle } from "../../common/js/upTitle";
import { mapGetters } from "vuex";
import barEcharts from "./components/bar_echarts";
import lineEcharts from "./components/line_echarts";
import {
  getTodayBoardList,
  getYestodayBoardList,
  getWeekBoardList,
} from "@/api/work";
export default {
  computed: {
    ...mapGetters(["departmentCode"]),
  },
  data() {
    return {
      dateList: [
        {
          dateItem: "今天",
          id: 0,
          isActive: true,
        },
        {
          dateItem: "昨天",
          id: 1,
          isActive: false,
        },
        {
          dateItem: "前七天",
          id: 2,
          isActive: false,
        },
      ],
      lineShow: true,
      barShow: true,
      lineList: [],
      barList: [],
      badCounts: 0,
      workOrderCounts: 0,
      fulfillCounts: 0,
      planCounts: 0,
      bad: 0,
    };
  },
  created() {
    ChangePageTitle("看板");
  },
  methods: {
    //跳转去新建工单
    toWorkOrder() {
      window.location.href = "/reportWork1";
    },
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.page = this.page + 1;
        this.getListData();
        done();
      }, 200);
    },
    headleDatChange(i) {
      this.dateList.forEach((v) => {
        v.isActive = false;
        if (v.id == i) {
          v.isActive = true;
        }
      });
      const index = this.dateList.findIndex((v) => v.id == i);
      if (this.dateList[index].id == 0) {
        this.getTodayBoardList();
      } else if (this.dateList[index].id == 1) {
        this.getYestodayBoardList();
      } else if (this.dateList[index].id == 2) {
        this.getWeekBoardList();
      }
    },
    //当天
    async getTodayBoardList() {
      try {
        const res = await getTodayBoardList(this["departmentCode"]);
        this.lineList = res.ranks;
        this.barList = res.classifications;
        this.badCounts = res.badCounts;
        this.fulfillCounts = res.fulfillCounts;
        this.planCounts = res.planCounts;
        this.workOrderCounts = res.workOrderCounts;
        if (res.planCounts == 0) {
          this.bad = 0;
        } else {
          this.bad = ((res.badCounts / res.planCounts) * 100).toFixed(2);
        }
      } catch (err) {
        console.log(err);
      }
    },
    //昨天
    async getYestodayBoardList() {
      try {
        const res = await getYestodayBoardList(this["departmentCode"]);
        this.lineList = res.ranks;
        this.barList = res.classifications;
        this.badCounts = res.badCounts;
        this.fulfillCounts = res.fulfillCounts;
        this.planCounts = res.planCounts;
        this.workOrderCounts = res.workOrderCounts;
        if (res.planCounts == 0) {
          this.bad = 0;
        } else {
          this.bad = ((res.badCounts / res.planCounts) * 100).toFixed(2);
        }
      } catch (err) {
        console.log(err);
      }
    },
    //一周
    async getWeekBoardList() {
      try {
        const res = await getWeekBoardList(this["departmentCode"]);
        this.lineList = res.ranks;
        this.barList = res.classifications;
        this.badCounts = res.badCounts;
        this.fulfillCounts = res.fulfillCounts;
        this.planCounts = res.planCounts;
        this.workOrderCounts = res.workOrderCounts;
        if (res.planCounts == 0) {
          this.bad = 0;
        } else {
          this.bad = ((res.badCounts / res.planCounts) * 100).toFixed(2);
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getTodayBoardList();
  },
  components: {
    scroll,
    barEcharts,
    lineEcharts,
  },
};
</script>
<style lang="less" scoped>
.borad {
  width: 100vw;
  height: calc(100vh - 85.32px);
  background-color: #f5f7f9;
  font-size: 30px;
  overflow: hidden;
  .borad_scroll {
    height: 100%;
    .information {
      width: 95%;
      margin: auto;
      margin-top: 20px;
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      .information_l {
        display: flex;
        align-items: center;
        .icon {
          font-size: 40px;
          margin-left: 10px;
          color: #07c160;
        }
      }
      .information_r {
        display: flex;
        div {
          background-color: #fff;
          margin-left: 10px;
          padding: 10px 15px;
        }
        .active {
          border: 1px solid #07c160;
          color: #07c160;
        }
      }
    }
    .borad_task {
      width: 95%;
      margin: auto;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .borad_task_item {
        width: 48%;
        height: 10vh;
        background-color: #fff;
        border-radius: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .item {
          color: blue;
        }
        .rate {
          color: red;
        }
        div:nth-child(2) {
          margin-top: 10px;
        }
      }
      .borad_task_item:nth-child(3) {
        margin-top: 2vh;
      }
      .borad_task_item:nth-child(4) {
        margin-top: 2vh;
      }
    }
    .ranking {
      width: 95%;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 10px 0;
    }
    #rank_line {
      width: 95%;
      margin: auto;
      border-radius: 10px;
      background-color: #fff;
    }
    .bar_echarts_foot {
      margin-bottom: 60px;
    }
    #rank_bar {
      width: 95%;
      box-sizing: border-box;
      background-color: #fff;
      margin: auto;
      border-radius: 10px;
      margin-bottom: 50px;
    }
    .line_echarts_img {
      width: 40%;
      box-sizing: border-box;
      background-color: #fff;
      margin: auto;
      img {
        width: 100%;
      }
    }
    .establish {
      width: 95%;
      margin: auto;
      text-align: center;
      margin-top: 20px;
      font-size: 30px;
      a {
        color: blue;
      }
    }
    .bar_echarts_img {
      width: 40%;
      box-sizing: border-box;
      background-color: #fff;
      margin: auto;
      img {
        width: 100%;
      }
    }
  }
  .conten_empty {
    margin-top: 30px;
    width: 100%;
    .conten_empty_img {
      width: 40%;
      height: 30%;
      margin: auto;
      img {
        width: 100%;
      }
    }
    .establish {
      font-size: 30px;
      margin-top: 30px;
      text-align: center;
      a {
        color: blue;
      }
    }
  }
}
</style>